<template>
  <div>
    <CButton
      class="p-1"
      v-bind="$attrs"
      :color="`${provider?.is_blocked ? 'primary' : 'danger'}`"
      @click="changeBlocked"
    >
      {{ provider?.is_blocked ? 'Розблокувати' : 'Заблокувати' }}
    </CButton>
    <modal-un-block-provider
      style="z-index: 9999;"
      :showUnBlock="showModal"
      :provider="providerID"
      @close="showModalUnBlock"/>
  </div>
</template>
<script lang="js">
import ModalUnBlockProvider from './Modal/BlockUnblockProvider';

export default {
  components: {ModalUnBlockProvider},
  inheritAttrs: true,
  props: {
    provider: {
      require: true,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      showModal: false,
      providerID: null,
      is_provider: null
    }
  },
  async created() {
    if (this.showModal) {
      // this.remark = await this.getRemark;
      this.is_provider = this.provider;
    }
  },
  methods: {
    getColor() {
      return this.provider?.is_blocked ? 'primary' : 'danger'
    },
    getText() {
      return this.is_provider?.is_blocked ? 'Розблокувати' : 'Заблокувати'
    },
    showModalUnBlock(show = false) {
      if (show) return this.showModal = true;

      this.showModal = false;
      setTimeout(() => this.providerID = null, 500)
    },
    changeBlocked() {
      this.providerID = {
        id: this.provider?.id,
        is_blocked: this.provider?.is_blocked,
        block_comment: this.provider?.block_comment,
      }
      this.showModalUnBlock(true)
    },
  }
}
</script>
