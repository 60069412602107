import axios from 'axios';

const APIBlockUnblock = (url, data = null) => {
    try {
        return axios.post(url, data).then(() => 'done');
    } catch (e) {
        return e.response.data;
    }
}

export const blockUnblockProvider = async (provider, remark) => {
    let send;
    if (provider?.is_blocked) {
        send = await APIBlockUnblock(`/api/providers/${provider?.id}/unblock`);
    } else {
        send = await APIBlockUnblock(`/api/providers/${provider?.id}/block`, {comment: remark});
    }
    // if (send === 'done') this.$store.dispatch('GET_PROVIDER');
    return send;
}
