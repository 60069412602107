<template>
  <CModal
    color="primary"
    title="Не верифіковувати"
    :show.sync="showRefuseVerificationModal"
    size="lg"
    addContentClasses="srl"
  >
    <template #body-wrapper>
      <CTextarea
        label="Напишіть зауваження."
        placeholder="Напишіть свої зауваження"
        cols="15"
        rows="3"
        class="m-2"
        :maxLength="$v.remark.$params.maxLength.max +1"
        @change="$v.remark.$touch()"
        :class=" {'error': $v.remark.$error}"
        v-model="remark"
      ></CTextarea>
    </template>
    <small v-if="!$v.remark.maxLength" class="form-text errorText w-100">Максимальна
      кількість символів
      {{ $v.remark.$params.maxLength.max }}!</small>
    <small v-if="!$v.remark.minLength || !$v.remark.required" class="form-text errorText w-100">Мінімальна
      кількість символів
      {{ $v.remark.$params.minLength.min }}, поле є обов'язковим!</small>
    <template #footer>
      <CButton
        @click="deleteProviders()"
        color="primary">
        Видалити
      </CButton>
    </template>
  </CModal>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {refusedProvider} from '../../../api/refusedProvider';

export default {
  name: "ModalRefuseVerification",
  props: {
    showRefuseVerificationModal: {
      required: true,
      default: false,
      type: Boolean
    },
    provider_id: {
      required: true,
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      remark: ''
    }
  },
  validations: {
    remark: {
      minLength: minLength(1),
      maxLength: maxLength(500),
      required
    }
  },
  methods: {
    updateProvider(e = null) {
      this.$emit('closed');
      this.store.dispatch('GET_PROVIDER');
    },
    async deleteProviders() {
      let id = this.$props.provider_id;
      if (this.comment === "") return;
      await refusedProvider(id, (e) => {
        this.updateProvider(e)
      });
      // axios.post(`/api/providers/${id}/refused`, {comment: self.remark})
      //   .then(function () {
      //     this.$emit('isRefuseVerification')
      //     self.$store.dispatch('GET_PROVIDER');
      //   })
      //   .catch(function () {
      //   });
    },
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
