import axios from 'axios';

const APIRefusedProvider = async (provider_id, comment) => {
    try {
        const url = `/api/providers/${provider_id}/refused`;
        return await axios.post(url, comment)
    } catch (e) {
        return e.response.data;
    }
}

export const refusedProvider = async (body, callback_func) => {
    const response = await APIRefusedProvider(body);

    if (response.status === 200) return callback_func({message: 'done'});
    callback_func(response);
}
