<template>
  <CModal
    :color="`${!provider?.is_blocked ? 'danger' : 'primary' }`"
    :title="`${!provider?.is_blocked ? 'Заблокувати': 'Розблокувати'} провайдера`"
    :show.sync="showUnBlock"
    size="lg"
    addContentClasses="srl"
  >
    <template #body-wrapper>
      <div class="px-4 py-2">
        <ul v-if="error.length >= 1" style="list-style: none" class="p-0">
          <li v-for="err of error" :key="error.indexOf(err)">
            <CAlert style="font-size: 12px;" class="text-left" show :color="err.color">{{ err.text }}</CAlert>
          </li>
        </ul>
        <div class="form-item" :class="{'errorInput': $v.remark.$error}">
          <label class="text-left">Зауваження на провайдера</label>
          <CTextarea
            required
            rows="9"
            :disabled="provider?.is_blocked"
            :placeholder="provider?.block_comment && provider?.is_blocked ? provider?.block_comment : 'Зауваження'"
            :maxlength="$v.remark.$params.maxLength.max +1 "
            @change="$v.remark.$touch()"
            :class=" {'error': $v.remark.$error}"
            v-model="remark"
          ></CTextarea>
          <small v-if="!$v.remark.maxLength" class="form-text errorText w-100">Максимальна
            кількість символів
            {{ $v.remark.$params.maxLength.max }}!</small>
          <small v-if="!$v.remark.minLength || !$v.remark.required" class="form-text errorText w-100">Мінімальна
            кількість символів
            {{ $v.remark.$params.minLength.min }}, поле є обов'язковим!</small>
        </div>
      </div>
    </template>
    <template #footer>
      <CButton
        color="secondary"
        @click="removeRemark"
      >
        Відмінити
      </CButton>
      <CButton
        :color="`${provider?.is_blocked ? 'primary' : 'danger'}`"
        @click="changeBlocked()"
      >
        {{ provider?.is_blocked ? 'Розблокувати' : 'Заблокувати' }}
      </CButton>
    </template>
  </CModal>
</template>

<script>
import {blockUnblockProvider} from "../../../api/blockUnblockProvider";
import {required, minLength, maxLength, requiredIf} from 'vuelidate/lib/validators'

export default {
  name: "BlockUnblockProvider",
  props: {
    showUnBlock: {
      type: Boolean,
      require: true,
      default: false
    },
    provider: {
      type: Object,
      require: true,
      default: null
    }
  },
  validations: {
    remark: {
      minLength: minLength(1),
      maxLength: maxLength(500),
      required: requiredIf(function () {
        return !this.provider?.is_blocked;
      })
    }
  },
  data: () => {
    return {
      remark: '',
      error: [],
      is_provider: null
    }
  },
  beforeDestroy() {
    this.clearDate();
  },
  watch: {
    showUnBlock(val) {
      this.$v.$reset();
      if (!val) return this.removeRemark();
    }
  },
  methods: {
    async changeBlocked() {
      if (!this.provider?.is_blocked) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let req = await blockUnblockProvider(this.provider, this.remark);
          if (req === 'done') this.doneSend();
          else this.errorSend(req);
        }
      } else {
        let req = await blockUnblockProvider(this.provider);
        if (req === 'done') this.doneSend();
        else this.errorSend(req);
      }
    },
    errorSend(err) {
      this.error.push({status: 'bad', color: 'danger', text: err})

      setTimeout(() => {
        this.removeRemark();
      }, 1000);
    },
    doneSend() {
      let text = this.provider?.is_blocked ? 'Провайдер розблокований!' : 'Коментар успішно доданий, провайдер заблокований!';
      this.error.push({status: 'done', color: 'success', text: text});

      setTimeout(() => {
        this.$store.dispatch('GET_PROVIDER');
        this.removeRemark();
      }, 1000);

    },
    removeRemark() {
      this.$emit('close', false);
      this.clearDate();
    },
    clearDate() {
      this.error = [];
      this.remark = '';
      this.is_provider = null
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
